<template>
  <div v-if="middleBanner && middleBanner.length">
    <VueSlickCarousel v-bind="settings">
      <div
        id="carousel-fashion"
        class="containers-image text-center"
        v-for="(image, index) in middleBanner"
        :key="index"
      >
        <img class="image" :src="image.url.fileUrl" />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "fashion-middle",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        touchMove: true,
      },
    };
  },
  computed: {
    ...mapState({
      middleBanner: ({ advertisement }) => advertisement.middleBanner,
    }),
  },
  methods: {
    ...mapActions({
      fetchFashionMiddle: "advertisement/fashionMiddleBanner",
    }),
  },

  mounted() {
    this.fetchFashionMiddle();
  },
};
</script>

<style scoped>
.containers-image {
  display: block;
  width: 50%;
  place-items: center;
}
.image {
  border-radius: 10px;
  height: 200px;
}
</style>
